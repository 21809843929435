.container {
    width: 100%;
    min-height: 90px;
    background-color: #0068ff;
    background-image: url('../../../../../img/svg/wave-2.svg');
    background-repeat: no-repeat;
    background-position: -20px -30px;
    background-size: cover;
    color: #fff;
    display: flex;
    position: relative;
    flex-direction: column;         
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
}

.info {
    display: flex;
    position: relative;
    flex-direction: column;         
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.container:hover {
    background-position: 0px -40px;

}

.count {
    font-size: 24px;
}

.add {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 34px;
    height: 34px;
    background-color: rgb(0, 84, 209);
    border-radius: 7px 0px 7px 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transition: all 0.4s ease-in-out;
}

.add:hover {
    background-color: rgb(1, 74, 184);
    width: 184px;
    gap: 5px;
}
.add .add_txt {
    position: relative;
    height: 0;
    width: 0;
    opacity: 0;
    right: -100px;
    // display: none;
    transition:  all 0.4s ease-out;
    
}
.add:hover .add_txt {
    // display: block;
    right: 0px;
    width: auto;
    height: auto;
    opacity: 1;
}

.add_txt {
    transition: all 0.4s ease-in-out;
    font-size: 14px;
}

.container.add:hover + .container.info {
    display: none;
    color: red;
}
// .block1:hover + .block2 h1 { color: red;}

.container a {
    color: #fff!important;
    text-decoration: none;
}