.navbar {
    background-color: #10062b;
    color: #ffffff!important;
    position: fixed;
    width: 100%;
    z-index: 100;
}

.navblock {
    margin-left: 50px;
}

.navblock a, .navbar div a{
    color: #ffffff!important;
}

.navblock a {
    // border-radius: 25px;
    border-bottom: 2px solid #10062b;
    transition: all 0.4s ease-in-out;
}
.navblock a:hover {
    // background-color:#c97bf3!important;
    border-bottom: 2px solid #0066ff;
    // color: #0066ff!important;
}

.nav__auth__btn {
    float: right
}

.language {
    border: 1px #0066ff solid;
    padding: 4px;
    border-radius: 4px;
    font-size: 10px;
    color: #f4f7ff;
    margin-left: 10px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}

.language:hover {
    border: 1px #004bbd solid;
    background-color: #004bbd;
}
