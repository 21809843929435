.row {
    margin-bottom: 10px;
}

.row div:last-child{
    float:right;
}

.buttonContainer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}