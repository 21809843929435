.save__container {
    padding: 10px;
    background-color: #10062b;
    color: white;
    transition: all 0.4s ease-in-out;
}

.save__block {
    justify-content: flex-end;
    display: flex;
    gap: 10px;
}



.save__ribborn {
    background: #c97bf3;
    height: 5px;
}