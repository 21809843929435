.avatar_container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 150px;
    overflow: hidden;
}


.avatar_container .avatar_load {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 8px;
    position: absolute;
    z-index: 1;
    width: 100%;
    cursor: pointer;
    bottom: -40px;
    transition: all 0.4s ease-in-out;
}
.avatar_container:hover .avatar_load {
    bottom: 0px;
}

.container {
    display: flex;
    margin-bottom: 20px;
}

.profile {
    margin-right: 10px;
    width: 60rem;
}

.mini_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: stretch;
    gap: 10px;

    &__block {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        margin: 0px;
    }
}

.partners div {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 992px) {
    .avatar_container .avatar_load {
        bottom: 0px;
    }
    
}