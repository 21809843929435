.container {
    padding: 8px;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    transition: all 0.4s ease-in-out;
}

.container:hover {
    border: 1px solid #ad69d5;
}

.count_block {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    padding: 8px;
    border-radius: 8px;
    transition: all 0.4s ease-in-out;
    background-color: #e9ecef;
    margin: 8px 0px;
}

.count_block p {
    margin-bottom: 4px;
    font-size: 12px;
}

.count_text {
    display: block;
    float: left;
    font-weight: 700;
    font-size: 20px;
}
.count_text span {
    // display: block;
    vertical-align: super;
    font-size: 10px;
    padding: 2px;
    font-weight: 400;
    background-color: #ad69d5;
    border-radius: 4px;
    color: #e9ecef;
    margin-left: 2px;
}
