.block {
    margin-bottom: 15px;
}

.onCart {
    // height: calc(100% - 4px);
    display: flex;
    flex-direction: column;
    gap: 10px;
    // justify-content: space-between;
}

.addMultilink {
    display: flex;
    // justify-content: space-between;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    background-color: #e9ecef;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
}

.addMultilink .info {
    font-size: smaller;
    color: #353535;
    text-align: center;
}

.independent {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    background: rgb(16, 6, 43);
    background: linear-gradient(
        45deg,
        rgba(16, 6, 43, 1) 0%,
        rgba(201, 123, 243, 1) 100%
    );
    color: #fff;
    font-family: "Roboto", sans-serif;
    padding: 8px;
    border-radius: 7px;
}

.items_container {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    // border: 1px solid #e9ecef;
    border-radius: 8px;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
}

.items_container .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e9ecef;
    padding: 18px;
    margin-bottom: 10px;
    border-radius: 8px;
}
.items_container .items {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    // padding: 18px;
    margin-bottom: 10px;

    gap: 18px;
}

// card
.buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    height: 100%;
}

.desc {
    color: rgb(160, 160, 160);
    font-size: 14px;
}