@import "../../../../../Styles/Test.module.scss";

.drop {
    display: flex;
    flex-flow: column nowrap;
    margin: 0px 0px 6px 0px;
    cursor:grab;
    position: relative;
    z-index: 1;
}

.drop.start {
    
    z-index: 10;
}
.drop.active {
    background-color: #e0e0e9;
    padding: 5px;

}

.input {
    position: relative;
    z-index: 5;
}


.input .del_edit{
    display: flex;
    justify-content:flex-end;
    align-items: flex-start;
    gap: 8px;
}