

.item {
    padding: 10px ;
    border: 1px solid #e9ecef;
    border-radius: 7px;
    transition: all ease-in-out .4s;
}
.item:hover {
    border: 1px solid #c578ef;
}