
.form {
    max-width: 400px;
    
}

.social {
    margin-bottom: 10px;
} 
.setting {
    width: 100%;
    padding: 10px;
    border: 1px solid #0068ff;
    margin-bottom: 40px;
    &__test{
        background-color: #0068ff;
    }
}
.logout__all {
    margin-top: 40px;
    margin-bottom: 40px;

}