.container {
    padding: 0px 10px 0px 10px;
}

.container .row {
    margin-bottom: 10px;
    padding: 10px 0px 10px 0px;
    border: 1px solid #f8faff;
    border-radius: 7px;
    transition: all ease-in-out .4s;
}
.container .row:hover {
    border: 1px solid #c578ef;
}

.background_block{
    color: #fff;
    margin-bottom: 10px;
    padding: 10px 0px 10px 0px;
    border: 1px solid #1a77ff;
    background-color: #1a77ff;
    border-radius: 7px;
    transition: all ease-in-out .4s;
}

.image_container {
    background-color: #c578ef;
}
.image_block {
    // background-color: #c578ef;
}
.image_filter {
    padding: 14px 0px 14px 0px;
}

.row div:last-child{
    float:right;
}
.background_block div:last-child{
    float:right;
}

.colorpicker {
    display: flex;
    flex-direction: row;
    gap:10px;
}

.user_color{
    display: flex;
    gap:10px;
}