.social{
    display:flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;



    &__button {
        padding: 8px;
        border: 1px solid #0068ff;
        color:#0068ff;
        border-radius: 4px;
        transition: all 0.4s ease-in-out;
        cursor: pointer;
        display: flex;
        width: 31%;
        gap: 5px;
    }
    &__button:hover{
        background-color:#0068ff;
        color: #ffffff;
    }
}

.h2 {
    color: #a9b0b8;
    font-size: 16px;
    font-weight: 400;
}

.header div:last-child {
    float: right !important;
}

.modal_size {
    width: 45%;
    max-width: none !important;
}