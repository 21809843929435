.container {
margin-top: 20px;
display: flex;
gap: 10px;
}

.no_create_shop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 20px;
    background-color: #e9ecef ;
    text-align: center;
    border-radius: 8px;
    gap: 10px;
}