
h2 {
    font-size: 21px;
}

.header {
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid #f1f3f5;
    margin-bottom: 10px;
    display: flex;
}

.container {
    width: 68%;
}

.title {
    width:100%;
    margin-right: 5px;
}

.mainpage {
    display: none;
    position: relative;
    transition: all 0.4s ease-in-out;
    background-color: #0068ff;
    color: #ffffff;
    font-size: 18px;
    border-radius: 4px;
    padding: 4px 0px 0px 0px;
    margin-right: 5px;
    width: 18px;
    min-height: 39px;


}
.mainpage.active {    
        display: block!important;
    } 

.back {
    &__btn {
        // background-color: ;
        display: none;
        position: relative;
        transition: all 0.4s ease-in-out;
        background-color: #0068ff;
        color: #ffffff;
        font-size: 18px;
        border-radius: 4px;
        padding: 4px 0px 0px 0px;
        cursor: pointer;
        margin-right: 5px;
        min-height: 39px;

    }
    &__btn:hover {
        background-color: #10062b;
        -webkit-box-shadow: 0px 2px 2px 1px rgba(16, 6, 43, 0.2);
        -moz-box-shadow: 0px 2px 2px 1px rgba(16, 6, 43, 0.2);
        box-shadow: 0px 2px 2px 1px rgba(16, 6, 43, 0.2);
        color: #ffffff;
        
    }
    &__btn.active {
        
        transition: all 0.4s ease-in-out;
        display: block!important;
    } 
}

.test {
    display: flex;
    align-items: center;
}

.dropdown_container {
    display: flex;
    align-items: center;
    gap: 10;
    padding: 0;
}

.dropdown_balance {
    font-weight: 700;
}

.language {
    display: flex;
    justify-content: center;
}

.multilink {
    display: flex;
    justify-content:space-between;
}

.h1 {
    font-size: 21px;
    line-height: 21px;
    margin-bottom: 0px;
}

.desc {
    color: rgb(160, 160, 160);
    font-size: 12px;
    margin-bottom: 0px;
}

.plan {
    min-width: 54px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background: rgb(201,123,243);
    background: linear-gradient(43deg, rgba(201,123,243,1) 0%, rgba(0,104,255,1) 100%);
    color: #fff;
    font-family: 'Roboto', sans-serif;
    padding: 2px 4px;
    border-radius: 4px;
    float: left;
    
    &__title {
        font-weight: 900;
        font-size: 14px;
    }

    &__desc{
        font-size: 9px;
        font-weight: 300;
    }

}