.copyright {
    display: block;
    position: relative;
    width: 100%;
    float: left;
    cursor:pointer;
    background-color: #f8faff;
    padding: 5px;
}
.copyright__block {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    color: #10062b;
    
    &__site {
        font-weight: 900;
    }

    &__deltext {
        display: none;
    }

    &__text {
        display: block;
    }
}

.copyright:hover .copyright__block__site{
    color: #0068ff;
    // text-decoration: underline;
} 
.copyright:hover .copyright__block__deltext{
    display: block;
}
.copyright:hover .copyright__block__text{
    display: none;
}