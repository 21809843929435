@import "../../../../../Styles/Test.module.scss";



.block {
    padding:0px 10px 10px 10px;

}

.background_block {
    color: #fff;
    margin-bottom: 10px;
    padding: 10px 0px 10px 0px;
    border: 1px solid #1a77ff;
    background-color: #1a77ff;
    border-radius: 7px;
    transition: all ease-in-out .4s;
}
.background_block.text {
    border: 1px solid #10062b;
    background-color: #10062b;
}


.marker div:last-child{
    float:right;
}
.marker.emoji {
    margin-top: 10px;
}