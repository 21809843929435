.container {
    height: 100vh;
    // background-image: url('./img/main-bg.jpg');
    background:rgba(0, 102, 255, 1) url("./img/main-bg2.png") no-repeat fixed center ;
    background-size: cover;
    color: #ffffff;
    display: flex;
    overflow: hidden;
}
.container.p {
    background-color: #10062b;
}

.container.container {
    position: relative;
    display: flex;
    align-items: center;
}


.h1 {
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    background-color: #10062b;
    color: #ffffff;
    display: inline;
    padding: 5px;
    -webkit-box-decoration-break: clone;
	box-decoration-break: clone;
}
.h1 span {
    color: #c97bf3!important;
}

.title {
    display: block;
    margin-bottom: 40px;
}

.desc {
    float: left;
    font-size: 21px;
    font-family: 'Roboto', sans-serif;
}

// .container::before {
//     content: "";
//     background-color: rgba(0, 102, 255, 0.705);

//     // background:  url("./img/main-bg.jpg") no-repeat fixed center ;
//     background-size: 100%;
//     opacity: 1;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     position: absolute;
//     z-index: -1;   
//   }