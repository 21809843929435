.row {
    margin-bottom: 10px;
}

.row div:last-child{
    float:right;
}

.colorpicker {
    display: flex;
    flex-direction: row;
    gap:10px;
}

.user_color{
    display: flex;
    gap:10px;
}