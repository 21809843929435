.container {
    padding: 0px 10px 0px 10px;
}

.background_block {
    color: #fff;
    margin-bottom: 10px;
    padding: 10px 0px 10px 0px;
    border: 1px solid #10062b;
    background-color: #10062b;
    border-radius: 7px;
    transition: all ease-in-out .4s;
}
.background_block.text {
    border: 1px solid #10062b;
    background-color: #10062b;
}


div.row div:last-child{
    float:right;
}