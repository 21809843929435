
.main__container {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    margin-bottom: 10px;
}

.button__container {
    display: flex;
    gap: 10px;
    justify-content:left;
    flex-wrap: wrap;
}

.statistics {
    min-width: 100px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: rgb(0,104,255);
    background: linear-gradient(57deg, rgba(0,104,255,1) 0%, rgba(34,124,255,1) 100%);
    border-radius: 7px;
    padding: 6px;
    // padding: 6px auto;
    // margin: auto 10px;

    &__item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 7px;
        background-color: #005ae2;
        color: #fff;
        padding: 6px;
        gap: 4px;

        &__text {
            font-size: 12px;
            float:left;
        }

        &__count {
            font-size: 14px;
            font-weight: 900;
                float:right;
        }
    }
}
