.container {
    display: flex;
    position: relative;
    height: 100vh;
    border-right: #e9ecef 1px solid;
    max-width: inherit;
    padding: 0px 10px 10px 10px;
}

.nav {
    position: fixed;
    width: calc(46% * 0.32);
}
.toggleMenu {
    display: flex;
    width: 100%;
    background-color: #10062b;
    margin-bottom: 18px;
}

.toggleBtn {
    flex: 1 1 auto;
    background-color: #ad69d5!important;
    border-color:  #ad69d5!important;
}
.toggleBtn:hover {
    background-color: #6b408e!important;
    border-color:  #6b408e!important;
}
.toggleBtn.active {
    background-color: #10062b!important;
    border-color:  #10062b!important;
}



.brand {
    display: flex;
    align-items: flex-start;

    &__logo {
        width: 100%;
        height: 62px;
        margin-left: 5px;

        &__img {
            display: block;
            width: 100%;
            padding: 0px;
            margin-top: 7px;
            line-height: 18px;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: bold;
            color: #10062b;
            transition: all 0.4s ease-in-out;
        }
        &__img:hover {
            color: #0068ff !important;
        }
        &__beta {
            text-transform: lowercase;
            font-size: 14px;
            font-weight: 100;
            color: #10062b;
        }
        &__desc {
            font-size: 12px;
        }
    }
}

.brans:hover {
    color: #0068ff;
}

.setting {
    position: absolute;
    bottom: 36px;
    width: 100%;
}

.menu {
    display: block;
    width: 100%;
    height: calc(100vh - 100px);
    float: left;
}

.menu_mobile {
    display: none;
}
.menu_toggle {
    display: none;
    margin-right: 10px;
}

.link {
    display: flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
    color: #7f8084;
    text-decoration: none;
    margin-bottom: 12px;
    transition: all 0.4s ease-in-out;
    width: 100%;
    border-radius: 7px;
}
.link:hover {
    color: #0068ff;
    background-color: #ffffff;
}
.link:hover span {
    background-color: #0068ff;
    color: #ffffff;
}

.link.active {
    background-color: #10062b;
    color: #ffffff;
}

.link span {
    transition: all 0.4s ease-in-out;
    background-color: #ffffff;
    color: #0068ff;
    padding: 8px;
    border-radius: 7px;
    display: inline-flex;
    margin-right: 6px;
}

.link.active span {
    background-color: #0068ff;
    -webkit-box-shadow: 0px 2px 2px 1px rgba(16, 104, 255, 0.2);
    -moz-box-shadow: 0px 2px 2px 1px rgba(16, 104, 255, 0.2);
    box-shadow: 0px 2px 2px 1px rgba(16, 104, 255, 0.2);
    color: #ffffff;
}

// .setting .link{
//     border: 1px solid #0068ff;
//     color: #0068ff;
// }
// .setting .link span{
//     background-color:#c97bf3;
//     color: #0068ff;
// }
// .setting .link:hover, .setting .link:hover span{
//     background-color:#10062b;
//     color: #ffffff;
//     border:1px solid #ffffff;
// }

.test {
    // background-color: #ffffff;
    border-radius: 7px;
}

@media (max-width: 767.98px) {
    .container {
        height: auto;
        border-right: none;
    }
    .brand {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
    }
    .menu {
        display: none;
    }
    .menu_mobile {
        display: block;
    }
    .menu_toggle {
        display: block;
    }
}
