
.main {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    background: rgb(16,6,43);
    background: linear-gradient(45deg, rgba(16,6,43,1) 0%, rgba(201,123,243,1) 100%);
    color: #fff;
    font-family: 'Roboto', sans-serif;
    padding: 8px;
    border-radius: 7px;
}

.main .buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.main .buttons .button {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    cursor: pointer;
}

.main .buttons .button:hover .icon{
    background-color: #10062b;
    padding: 6px;
    color: #fff;
}

.main .buttons .icon {
    font-size: 32px;
    border-radius: 48px;
    background-color: #fff;
    padding:5px;
    color:#6b408e;
    transition: all 0.4s ease-in-out;
    
}

.main .buttons .button p{
    font-size: 12px;

}
