
.language {
    // width: 24px;
    border: 1px #0066ff solid;
    padding: 4px;
    border-radius: 4px;
    font-size: 10px;
    color: #0066ff;
    margin-left: 10px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}

.language:hover {
    border: 1px #004bbd solid;
    background-color: #004bbd;
    color: #ffffff;
}
