.slide {
    height: 120px;
    width: 100%;
    min-width: calc(9 / 16 * 120px);
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    justify-content: center; 
    align-items: center;  
    cursor: pointer;
}

.slide.active {
    background-color: #f1f1f1;
}
.name_block {
    color: #bcc2c9;
    font-size: 15px;
    margin-top: 12px;
}