.container {
    height: 350px;
    overflow: auto;
}

.block {
    display: flex;
    flex-flow: row wrap;
    max-width: 100%;

}

.emoji {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width:44px;
    height:44px; 
    transition: all 0.4s ease-in-out; 
    border-radius: 2px;  
    font-size: 28px;
    cursor: pointer;
}
.emoji:hover {
    
    background-color:#10062b;
}