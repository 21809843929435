.container {
    display: flex;
    flex-wrap: wrap;
    // flex-direction: row;
    // align-items: start;
    // align-content: stretch;
    // height: 100%;
    gap: 16px;
    padding: 10px;
    border: 1px solid #e9ecef;
    border-radius: 8px;
}
.card {
    width: 32.1%;
}

.card_body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.title_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.title_container > h5 {
    margin: 0;
}
.title_container > button {
    margin: 0;
    cursor: pointer;
}

.info {
    display: flex;
    flex-direction: column;
    // justify-content: flex-start;
    // align-items: flex-start;
    // align-content: center;
    // text-align: center;
    gap: 15px;
    position: sticky;
    background-color: #e9ecef;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
}
.check {
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: sticky;
    // background-color: #e9ecef;
    // padding: 20px;
    margin-bottom: 10px;
    border-radius: 8px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: sticky;
    border: 2px solid #e9ecef;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
}

.btn {
    float: right;
}

.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}



  @keyframes rollout {
    0% {
        -webkit-transform: translateX(15px);
                transform: translateX(15px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateX(0px);
                transform: translateX(0px);
        opacity: 1;
      }
  }
  
  .roll_out {
    opacity: 0;
    animation: rollout 1s ease-in-out .3ms 1 normal forwards
  }