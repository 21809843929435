.sidebar {
    position: sticky;
    top: 0;
    z-index: 1;
}

:global(.canvas_container) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    align-content: center;
    text-align: center;
    gap: 15px;
    position: sticky;
    background-color: #e9ecef;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
}

.main_info {
    display: block;
    margin-bottom: 20px;
    padding: 20px;
    border: 2px solid #ad69d5;
    border-radius: 8px;
}

.design {

    background-color: #e9ecef;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
}

.tab {
    padding-top:20px ;
}

.block {
    margin-bottom: 10px;
    display: block;
}
.block:last-child {
    margin-bottom: 0px;
}