
.MultilinkItems {
 
}

.buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    height: 100%;
}

.h2 {
    font-size: 24px;
    margin-bottom: 2px;
}

.desc {
    color: rgb(160, 160, 160);
    font-size: 14px;
}

.card__footer {
    display: flex;
    justify-content:  space-between;
    font-size: 14px;
    align-items: 14px;

}
.card__footer div {
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    padding: 3px;
    display: flex;
    align-items: center;
    gap: 3px;
}

.card__footer a {
    color: #10062b;
    transition: all 0.4s ease-in;
}
.card__footer a:hover {
    color:#0068ff;
}
