.addButton {
    border: 1px solid #10062b;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius:8px;
    cursor:pointer;
    min-width: 23.7%;
    min-height: 36px;
    transition: all 0.4ms ease-in-out;
}
.addButton:hover {
    color:#ad69d5;
    border: 1px solid #ad69d5;
    ;

}
.icon {
    font-size: 24px;
    display: inherit;
}