.block {
    display: flex;
    align-items: center;   
    gap:10px;
}

:global(.offcanvas-backdrop){
    background-color: none;
    position: relative;
top: 0;
left: 0;
z-index: 1040;
}

:global(.offcanvas.show) {
    backdrop-filter: none;
  }