.container  {
margin-top: 4px;
}

// :global(.carousel-inner) {
//     display: flex;
// }

.carouselItem {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 5px;
    background-image: url('../../../../img/svg/wave.svg');
    background-color: #ad69d5;
    background-repeat: no-repeat;
    background-position: -10px 20px;
    background-size: cover;
    border-radius: 8px;
    margin: 2px;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    text-decoration: none;
}

.carouselItem:hover {
    background-position: -10px 10px;
    background-color: #10062b;
}

.carouselItem .title {
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    text-align: center;

}