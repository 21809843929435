.multilink {
    
    margin-top: 19px;
    padding: 4px;
    border-radius: 4px 4px 26px 26px;
    display: block;
    // position: relative !important;
    float: left;
    width: 230px;
    height: 400px;
    // overflow: hidden;
    z-index: 1;
    


}
.multilink::-webkit-scrollbar {
    width: none;               /* ширина scrollbar */
}
.multilink::-webkit-scrollbar-track {
    background: #10062b;        /* цвет дорожки */
}
.multilink::-webkit-scrollbar-thumb {
    background-color: #0068ff;    /* цвет плашки */
    border-radius: 20px;       /* закругления плашки */
    border: 1px solid #10062b;  /* padding вокруг плашки */
}

.container {
    // margin-top: 1px;
    display: block;
    position: relative;
    z-index: 0;
    width: 100%;
    float: left;
    cursor:grab;
    // touch-action: manipulation;
}
.setting {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    top: 0px;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    color:#fff;
  

    &__grip {


    }

    &__edit {

    }
}

.container:hover .setting  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    opacity: 1;
}


.switchTools {
    display: flex;
    // justify-content: center;
    padding: 2px;
}