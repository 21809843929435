.container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    gap: 15px;
}

.container button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    // align-content: center;
    // text-align: center;
}